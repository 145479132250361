import { Product, TProduct } from './product.types'

export const isValidProduct = (product: unknown): product is TProduct => {
  const result = Product.safeParse(product)

  if ('error' in result) {
    console.log(`Failed to parse product. Error: ${result.error}`)
    return false
  }

  return true
}
