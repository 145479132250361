import NextLink from 'next/link'
import { forwardRef } from '@chakra-ui/react'

import { trackEvent } from '@tofu/shared/utils/analytics'
import { isRelativeURL, isInternalURL } from './link.utils'
import { TLinkProps, TRenderLink } from './link.types'

/**
 * TODO: Refactor this to remove forwardRef
 * and document not to use this as a as={Link} (footer)
 *
 * - issues with styles not getting passed through properly
 * - the on click handler doesn't get fired.
 *
 * Josh's suggestion to make a withTracking hoc makes sense in that case
 * https://github.com/allplants/tofu/pull/524#discussion_r1050603443
 * this should be a shared hoc /shared/hocs/withTracking that we can wrap around any component
 *
 */

const renderAnchorLink: TRenderLink = (props) => {
  const { children, href, name = 'button.click', label, ...rest } = props

  /* istanbul ignore next */
  const onHandleClick = () => {
    /* istanbul ignore next */
    if (label) {
      /* istanbul ignore next */
      trackEvent(name, { label })
    }
  }

  return (
    <a href={href} onClick={onHandleClick} {...rest}>
      {children}
    </a>
  )
}

export const Link = forwardRef<TLinkProps, 'a'>((props, _ref) => {
  const { href, isExternal, shallow, as } = props

  if (isExternal) {
    /* istanbul ignore next */
    return renderAnchorLink(props)
  }

  /* istanbul ignore next */
  if (isRelativeURL(href) || isInternalURL(href)) {
    return (
      <NextLink as={as} href={href} passHref shallow={shallow}>
        {renderAnchorLink({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          'data-testid': 'next-link',
          ...props
        })}
      </NextLink>
    )
  }

  /* istanbul ignore next */
  return renderAnchorLink(props)
})
