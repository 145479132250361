/* istanbul ignore file */
import { Box } from '@tofu/shared/ui/atoms/box'
import { TProductCardTagNew } from './product-card-tag-new.types'
import { getTypeTag } from './product-card-tag-new.utils'

export const ProductCardTagNew: TProductCardTagNew = ({ type, ...rest }) => {
  if (!type) return null

  const renderTag = getTypeTag(type)

  if (!renderTag) return null

  return <Box {...rest}>{renderTag}</Box>
}
