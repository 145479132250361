// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { forwardRef, RefObject, useRef } from 'react'

import ReactMultiCarousel from 'react-multi-carousel'
import { Box } from '@tofu/shared/ui/atoms/box'
import { usePreventVerticalScroll } from '@tofu/shared/hooks/use-prevent-vertical-scroll'

import { CarouselDots } from './carousel-dots'
import { TCarouselProps } from './carousel.types'

const styleOverrides = {
  position: 'relative',
  // move the dots down a bit
  '.react-multi-carousel-dot-list': {
    zIndex: '100',
    bottom: '-40px'
  }
}

const Carousel = forwardRef(
  ({ customStyles, ...carouselProps }: TCarouselProps, ref) => {
    const containerRef = useRef<RefObject<HTMLElement | null>>()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    usePreventVerticalScroll(containerRef)

    const styles = customStyles ? { ...customStyles } : { ...styleOverrides }

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Box sx={styles} ref={containerRef} data-testid='carousel'>
        <ReactMultiCarousel
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={ref}
          minimumTouchDrag={20}
          renderDotsOutside={true}
          customDot={<CarouselDots />}
          {...carouselProps}
        />
      </Box>
    )
  }
)

export { Carousel }
