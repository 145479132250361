import { toBase64 } from '@tofu/shared/utils/to-base'

export const shimmerSvgAsDataString = `data:image/svg+xml;base64,${toBase64(
  `<svg
    width='500px'
    height='500px'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlns:xlink='http://www.w3.org/1999/xlink'
  >
    <defs>
      <linearGradient id='g'>
        <stop stop-color='#EEE' offset='20%' />
        <stop stop-color='#CCC' offset='50%' />
        <stop stop-color='#EEE' offset='70%' />
      </linearGradient>
    </defs>
    <rect width='500px' height='500px' fill='#EEE' />
    <rect id='r' width='500px' height='500px' fill='url(#g)' />
    <animate
      xlink:href='#r'
      attributeName='x'
      from='-500px'
      to='500px'
      dur='1s'
      repeatCount='indefinite'
    />
  </svg>`
)}`
