import startsWith from 'lodash/fp/startsWith'
import includes from 'lodash/fp/includes'
import negate from 'lodash/fp/negate'

import { environment } from '@tofu/shared/constants/environments'

/**
 * Checks for absolute allplants.com links
 * the // startes the condition after either https// or http//
 */
const isInternalURL = includes(`${environment.shop_base_url}`)
const isExternalURL = negate(isInternalURL)

/**
 * Check for links that start with a slash
 */
const isRelativeURL = startsWith('/')

export { isRelativeURL, isInternalURL, isExternalURL }
