import get from 'lodash/fp/get'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import keyBy from 'lodash/fp/keyBy'
import split from 'lodash/fp/split'
import trim from 'lodash/fp/trim'
import toLower from 'lodash/fp/toLower'
import compact from 'lodash/fp/compact'

const getProductSlideCategoryTitle = get('product_slide_category_title')
const getProductSlideCategoryTitles = map(getProductSlideCategoryTitle)

export const getCategoryTitles = flow(getProductSlideCategoryTitles, compact)

export const keyByProductSlideCategoryTitle = flow(
  compact,
  keyBy('product_slide_category_title')
)

const getProductSlideProducts = get('product_slide_products')
const sanitiseHandleString = flow(trim, toLower)
export const getProductHandles = flow(
  getProductSlideProducts,
  split(','),
  map(sanitiseHandleString)
)
