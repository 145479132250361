import { Wrap, WrapItem } from '@chakra-ui/react'

import { ProductCardTagNew } from '@tofu/shared/ui/molecules/product-card-tag-new'
import { TProductCardTags } from './product-card-tags.types'

export const ProductCardTags: TProductCardTags = ({ isNew, tags }) => (
  <Wrap display={['flex', 'flex', 'flex']}>
    {isNew && (
      <WrapItem>
        <ProductCardTagNew key='new' type='new' />
      </WrapItem>
    )}
    {tags &&
      tags.map((tag) => (
        <WrapItem>
          <ProductCardTagNew key={tag} type={tag} />
        </WrapItem>
      ))}
  </Wrap>
)
