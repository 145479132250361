import Image, { ImageLoader } from 'next/image'

import { Box } from '@tofu/shared/ui/atoms/box'

import { TProductCardImage } from './product-card-image.types'
import { shimmerSvgAsDataString } from './product-card-image.utils'

const imageLoader: ImageLoader = ({ src }) =>
  `${src}?width=600&height=600&q=40&format=webp`

export const ProductCardImage: TProductCardImage = ({
  title,
  ...propsImage
}) => (
  <Box overflow='hidden' width={'100%'} height={'100%'}>
    <Image
      placeholder='blur'
      blurDataURL={shimmerSvgAsDataString}
      alt={title}
      layout='responsive'
      width={460}
      height={460}
      loader={imageLoader}
      {...propsImage}
    />
  </Box>
)
