import head from 'lodash/fp/head'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import keyBy from 'lodash/fp/keyBy'
import filter from 'lodash/fp/filter'
import curry from 'lodash/fp/curry'
import find from 'lodash/fp/find'
import map from 'lodash/fp/map'
import equals from 'lodash/fp/equals'
import size from 'lodash/fp/size'
import isEmpty from 'lodash/fp/isEmpty'
import omitBy from 'lodash/fp/omitBy'
import merge from 'lodash/merge'

import { TProduct, TVariantPortionSize } from '@tofu/shared/types/product'
import { HIDDEN_PRODUCTS } from '@tofu/shared/constants/products'

import {
  TNormalisedProductVariant,
  TNormalisedProduct,
  TNormalisedProductVariantsRecord,
  TVariantsStatus
} from './products-normaliser.types'

import { DISCOUNTS } from './products-normaliser.constants'

const isSoldOut = equals('SOLD_OUT')
const getStockStatus = get('stock_status')
const getPortionSize = get('portion_size')
const getPrice = get('price')
const getVariants = get('variants')

export const generateKeysFromHandles = keyBy('handle')
export const generateKeysFromPortionSize = keyBy('portion_size')

/**
 * omitByNoHandles
 * saftey check to remove any products handles that might be in DISCOUNTS
 * and not in the products data.
 */
const omitByNoHandles = omitBy(({ handle }: TProduct) => !handle)
const mergeDiscounts = (products: TProduct[]) => merge(products, DISCOUNTS)

export const addProductDiscounts = flow(mergeDiscounts, omitByNoHandles)

const divideEachVariantPriceBy100 = map(
  (variant: TNormalisedProductVariant) => ({
    ...variant,
    price: variant.price / 100
  })
)

export const formatVariantsPrice = map((product: TProduct) => ({
  ...product,
  variants: divideEachVariantPriceBy100(product.variants)
}))

export const generateVariantsKeys = map((product: TProduct) => ({
  ...product,
  variants: generateKeysFromPortionSize(product.variants)
}))

const isProductVisible = (product: TProduct) =>
  !HIDDEN_PRODUCTS.includes(product.handle)

export const removeHiddenProducts = filter(isProductVisible)

export const getPriceFromVariant = curry(
  (portion_size: TVariantPortionSize, product: TProduct) =>
    flow(getVariants, find({ portion_size }), getPrice)(product)
)

export const getSinglePrice = getPriceFromVariant('SINGLE_PORTION')
export const getDoublePrice = getPriceFromVariant('DOUBLE_PORTION')

const isStockStatusSoldOut = flow(getStockStatus, isSoldOut)

const filterBySoldOutVariants = filter(isStockStatusSoldOut)
const getPortionSizeForEachVariant = map(getPortionSize)

const getArrayOfSoldOutVariants = flow(
  filterBySoldOutVariants,
  getPortionSizeForEachVariant
)

export const getVariantsStockStatus = (
  variants: TNormalisedProductVariantsRecord
): TVariantsStatus => {
  const soldOutVariantsList = getArrayOfSoldOutVariants(variants)

  if (isEmpty(soldOutVariantsList)) {
    return 'AVAILABLE'
  }

  if (size(soldOutVariantsList) === size(variants)) {
    return 'SOLD_OUT'
  }

  const soldOutVariant: TVariantPortionSize = head(soldOutVariantsList)

  return `SOLD_OUT_${soldOutVariant}`
}

export const formatStockStatus = map((product: TNormalisedProduct) => ({
  ...product,
  variantsStockStatus: getVariantsStockStatus(product.variants)
}))

export const addCustomPortionSizes = map((product: TNormalisedProduct) => {
  if (product?.handle === 'symplicity-cumberland-sausages') {
    return {
      ...product,
      customPortionSize: 4
    }
  }

  if (product?.handle === 'symplicity-burgers') {
    return {
      ...product,
      customPortionSize: 2
    }
  }
  return { ...product }
})
