import { Box } from '@tofu/shared/ui/atoms/box'
import { Text } from '@tofu/shared/ui/atoms/text'
import { HStack } from '@tofu/shared/ui/atoms/stack'

import { TProductCardPriceInfo } from './product-card-price-info.types'

export const ProductCardPriceInfo: TProductCardPriceInfo = ({
  price,
  ...propsText
}) => (
  <HStack justifyItems='space-between' width='100%' alignItems='bottom'>
    <Box width='100%'>
      <HStack spacing={2}>
        <Text as='span' fontSize='md' {...propsText}>
          {price}
        </Text>
      </HStack>
    </Box>
  </HStack>
)
