import { useEffect, useState, useRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'
import { trackClick } from '@tofu/shared/utils/analytics'

import { useTrimmedProductsQuery } from '@tofu/shared/hooks/use-trimmed-products-query'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Carousel } from '@tofu/shared/ui/atoms/carousel'
import { TTrimmedNormalisedProduct } from '@tofu/shared/types/trimmed-product'

import { responsiveCarouselSettings } from '../slice-product-carousel.constants'
import { SliceProductCarouselSliderFilters } from '../slice-product-carousel-slider-filters'
import { SliceProductCarouselItem } from '../slice-product-carousel-item'
import { TSliceProductCarouselSliderCustom } from './slice-product-carousel-slider-custom.types'
import {
  getCategoryTitles,
  keyByProductSlideCategoryTitle,
  getProductHandles
} from './slice-product-carousel-slider-custom.utils'

export const SliceProductCarouselSliderCustom: TSliceProductCarouselSliderCustom =
  ({ display_navigation, display_add_buttons, items, onClickAdd }) => {
    const filtersList: string[] = getCategoryTitles(items)
    const [activeCategory, setActiveCategory] = useState(filtersList?.[0])
    const sliderRef = useRef()
    const { data: products } = useTrimmedProductsQuery()

    const categorisedItems = keyByProductSlideCategoryTitle(items)
    const activeCategoryItems =
      categorisedItems?.[activeCategory] || categorisedItems?.['null'] || {}
    const activeCategoryProductHandles = getProductHandles(activeCategoryItems)
    const activeCategorySliderProducts = activeCategoryProductHandles.map(
      (handle: string) => ({
        ...products?.[handle]
      })
    )
    const activeCategorySliderProductsArray = values(
      activeCategorySliderProducts
    )

    useEffect(() => {
      /* istanbul ignore next */
      if (sliderRef.current) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sliderRef?.current?.goToSlide(0)
      }
    }, [activeCategory])

    const handleOnFilterClick = (category: string) => {
      setActiveCategory(category)
      trackClick('product.carousel.filter', { label: category })
    }

    return (
      <div data-testid='product-carousel-custom'>
        {display_navigation && !isEmpty(filtersList) && (
          <SliceProductCarouselSliderFilters
            activeFilter={activeCategory}
            filtersList={filtersList}
            handleClick={handleOnFilterClick}
          />
        )}
        {activeCategorySliderProductsArray.length > 0 && (
          <Box
            sx={{
              '.react-multi-carousel-arrow ': {
                marginTop: '-70px'
              },
              '.react-multi-carousel-arrow-right': {
                right: '0'
              },
              '.react-multi-carousel-arrow-left': {
                left: '0'
              }
            }}
          >
            <Carousel
              ref={sliderRef}
              arrows
              centerMode={false}
              showDots={false}
              renderDotsOutside={true}
              slidesToSlide={1}
              swipeable
              infinite={true}
              responsive={responsiveCarouselSettings}
            >
              {activeCategorySliderProductsArray.map(
                (product: TTrimmedNormalisedProduct, index: number) => (
                  <SliceProductCarouselItem
                    key={`${product.id}_${index}`}
                    display_add_buttons={display_add_buttons}
                    onClickAdd={onClickAdd}
                    {...product}
                  />
                )
              )}
            </Carousel>
          </Box>
        )}
      </div>
    )
  }
