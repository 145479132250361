import { createContext, useContext } from 'react'
import {
  TStaticContentProvider,
  TStaticContentProps
} from './static-content-provider.types'

export const StaticContentContext = createContext<TStaticContentProps>({
  carouselProducts: {
    Meals: [],
    Smoothies: [],
    Treats: [],
    Breakfasts: [],
    Sides: []
  },
  promoBanner: {
    text: [],
    isBannerVisible: false,
    isBannerSticky: false,
    colorScheme: ''
  }
})
export const useStaticContentContext = () => useContext(StaticContentContext)

export const StaticContentProvider: TStaticContentProvider = ({
  children,
  promoBanner,
  carouselProducts
}) => {
  return (
    <StaticContentContext.Provider value={{ carouselProducts, promoBanner }}>
      {children}
    </StaticContentContext.Provider>
  )
}
