import { Box } from '@tofu/shared/ui/atoms/box'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import { Text } from '@tofu/shared/ui/atoms/text'
import { Button } from '@tofu/shared/ui/atoms/button'

import { TSliceProductCarouselSliderFilters } from './slice-product-carousel-slider-filters.types'

export const SliceProductCarouselSliderFilters: TSliceProductCarouselSliderFilters =
  ({ activeFilter, filtersList, handleClick }) => {
    if (filtersList.length === 0) return null
    return (
      <Box
        overflowX={['scroll', 'inherit', 'inherit']}
        data-testid='product-carousel-slider-filters'
      >
        <Flex as='nav' justifyContent={['flex-start', 'center']} mb={[2, 3]}>
          {filtersList.map((name) => (
            <Box key={name}>
              <Button
                id={`product_type_${name}`}
                name='select_product_type'
                onClick={() => handleClick(name)}
                px={3}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                    p: {
                      color: 'grey.900'
                    }
                  }
                }}
              >
                <Text
                  sx={
                    activeFilter === name
                      ? {
                          fontWeight: 'bold',
                          borderBottom: `2px solid black`
                        }
                      : {
                          fontWeight: 'light',
                          color: 'grey.700'
                        }
                  }
                >
                  {name}
                </Text>
              </Button>
            </Box>
          ))}
        </Flex>
      </Box>
    )
  }
