import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import isEmpty from 'lodash/fp/isEmpty'
import negate from 'lodash/fp/negate'
import some from 'lodash/fp/some'
import { THasProducts } from './slice-product-carousel.types'

const getProductSlideProducts = get('product_slide_products')

export const hasProducts: THasProducts = some(
  flow(getProductSlideProducts, negate(isEmpty))
)
