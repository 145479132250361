import { Circle } from '@chakra-ui/react'
import { DotProps } from 'react-multi-carousel'

import { Box } from '@tofu/shared/ui/atoms/box'
import { Button } from '@tofu/shared/ui/atoms/button'

const getStyles = (active = false) => ({
  bg: active ? 'blueberry.main' : 'grey.300'
})

export const CarouselDots = ({ index, onClick, active }: DotProps) => (
  <Box as='li' w='20px' mx={1} key={index}>
    <Button onClick={onClick} w='12px' h='28px'>
      <Circle size='12px' {...getStyles(active)} />
    </Button>
  </Box>
)
