import { VStack, TStackProps } from '@tofu/shared/ui/atoms/stack'

export const ProductCardContent = (props: TStackProps) => (
  <VStack
    alignItems='baseline'
    height='100%'
    w='100%'
    spacing={0}
    pt={3}
    justify='space-between'
    sx={{
      marginTop: '0 !important'
    }}
    {...props}
  />
)
