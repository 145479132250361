import { useEffect, useRef } from 'react'
import { TGetAttributes, TTrustpilotWidget } from './trustpilot-widget.types'

const getAttributes: TGetAttributes = (variant) => {
  /* istanbul ignore next */
  switch (variant) {
    case 'micro':
      return {
        'data-template-id': '5419b6ffb0d04a076446a9af',
        'data-style-height': '40px'
      }
    case 'slim':
      return {
        'data-template-id': '5406e65db0d04a09e042d5fc',
        'data-stars': '4,5',
        'data-style-height': '28px'
      }
    case 'standard':
      return {
        'data-template-id': '54ad5defc6454f065c28af8b',
        'data-stars': '4,5',
        'data-style-height': '240px'
      }
    case 'top9':
      return {
        'data-template-id': '54ad5defc6454f065c28af8b',
        'data-style-height': '240px',
        'data-tags': 'top9'
      }
    default:
      return {}
  }
}

export const TrustpilotWidget: TTrustpilotWidget = ({ variant = 'top9' }) => {
  const widgetRef = useRef<HTMLDivElement>(null)

  /* istanbul ignore next */
  useEffect(() => {
    if (typeof window !== 'undefined' && window['Trustpilot'] && widgetRef) {
      window['Trustpilot']?.loadFromElement(widgetRef.current)
    }
  }, [widgetRef])

  return (
    <div
      ref={widgetRef}
      className='trustpilot-widget'
      data-businessunit-id='592599400000ff0005a31b8f'
      data-locale='en-GB'
      data-style-width='100%'
      data-testid='trustpilot-widget'
      data-theme='light'
      {...getAttributes(variant)}
    />
  )
}
