export const HIDDEN_PRODUCTS = [
  'tempeh-rendang-curry',
  'creamy-pesto-greens',
  'bbq-burrito-bowl-2',
  'spinach-nut-ricotta-lasagne',
  'thai-green-curry-2',
  'butternut-barley-risotto',
  'tofu-massaman-curry',
  'classic-cottage-pie',
  'roasted-celeriac-butter-bean-pie',
  'spiced-squash-grain-bowl',
  'hearty-mushroom-hotpot',
  'peri-peri-strips-spicy-rice',
  'smoky-chicken-chorizo-stew',
  'chicken-tikka-masala',
  'spicy-thai-red-curry',
  'black-bean-broccoli-udon'
]
