import Link from 'next/link'
import { RichText } from 'prismic-reactjs'

import { Box } from '@tofu/shared/ui/atoms/box'
import { Button } from '@tofu/shared/ui/atoms/button'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import { Text } from '@tofu/shared/ui/atoms/text'
import { trackClick } from '@tofu/shared/utils/analytics'

import { TSliceProductCarousel } from './slice-product-carousel.types'
import { hasProducts } from './slice-product-carousel.utils'
import { SliceProductCarouselSliderDefault } from './slice-product-carousel-slider-default'
import { SliceProductCarouselSliderCustom } from './slice-product-carousel-slider-custom'
import { SliceProductCarouselTrustPilot } from './slice-product-carousel-trust-pilot'

/* istanbul ignore next */
const handleOnAddClick = (handle: string) => {
  /* istanbul ignore next */
  trackClick('product.carousel.add', { label: handle })
}

export const SliceProductCarousel: TSliceProductCarousel = ({
  cta,
  cta_url,
  description,
  display_add_buttons = true,
  display_navigation = true,
  display_trustpilot = false,
  items = [],
  title
}) => {
  // const { push } = useRouter()
  const showDefaultCarousel = !hasProducts(items)

  // Istansbul ignore here coz this component just renders out an empty container.
  // so no way to pass products unless we mock both react query and also useStaticContent.
  // I would rather refactor this to use the container => component pattern.
  // Pull products into the container and pass them into the child components.
  /* istanbul ignore next */

  return (
    <Box maxWidth='container.xl' mx='auto' px={4}>
      {title && (
        <Text
          as='h2'
          textStyle='h3'
          textAlign='center'
          mb={3}
          ml='auto'
          mr='auto'
          maxWidth='container.lg'
        >
          {RichText.asText(title)}
        </Text>
      )}

      {display_trustpilot && (
        <Box mt={6}>
          <SliceProductCarouselTrustPilot />
        </Box>
      )}

      {description && (
        <Text
          textAlign='center'
          maxWidth='container.sm'
          ml='auto'
          mr='auto'
          mb={3}
        >
          {RichText.asText(description)}
        </Text>
      )}

      {showDefaultCarousel && (
        <SliceProductCarouselSliderDefault
          onClickAdd={handleOnAddClick}
          display_navigation={display_navigation}
          display_add_buttons={display_add_buttons}
        />
      )}

      {!showDefaultCarousel && (
        <SliceProductCarouselSliderCustom
          items={items}
          onClickAdd={handleOnAddClick}
          display_navigation={display_navigation}
          display_add_buttons={display_add_buttons}
        />
      )}

      {cta && cta_url && cta_url?.url && (
        <Flex justifyContent='center' mt={4}>
          <Link href={cta_url.url} passHref>
            <Button
              as='a'
              name='carousel'
              variant='solid'
              colorScheme='blackAlpha'
              size='lg'
            >
              {cta}
            </Button>
          </Link>
        </Flex>
      )}
    </Box>
  )
}
