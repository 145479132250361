/**
 * TODO: conver these type to Partial<TProduct> and Partial<TProductVariant>
 * My TS still's aren't good enough to do this tho.
 * Something like this: https://grrr.tech/posts/2021/typescript-partial/#in-conclusion
 */

type DiscountVariant = {
  original_price: number
  discounted_percentage?: number
}

type Discount = {
  variants: {
    DOUBLE_PORTION?: DiscountVariant
    SINGLE_PORTION?: DiscountVariant
  }
}

type TDiscounts = {
  [key: string]: Discount
}

export const DISCOUNTS: TDiscounts = {
  'butternut-lasagne-with-cashew-bechamel': {
    variants: {
      DOUBLE_PORTION: {
        original_price: 10.25,
        discounted_percentage: 10
      }
    }
  }
}
