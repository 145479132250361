import { forwardRef } from '@chakra-ui/react'

import { Button } from '@tofu/shared/ui/atoms/button'
import { TProductCardClickableArea } from './product-card-clickable-area.types'

export const ProductCardClickableArea: TProductCardClickableArea = forwardRef(
  (propsButton, ref) => (
    <Button
      ref={ref}
      variant='invisible'
      _active={{
        boxShadow: `inset var(--chakra-shadows-outline)`
      }}
      _focus={{
        boxShadow: `inset var(--chakra-shadows-outline)`
      }}
      {...propsButton}
    />
  )
)
