import {
  TTrimmedNormalisedProduct,
  TTrimmedProduct
} from '@tofu/shared/types/trimmed-product'

export type TCarouselProduct = Pick<
  TTrimmedNormalisedProduct,
  | 'handle'
  | 'short_description'
  | 'image_url'
  | 'title'
  | 'variants'
  | 'display_award_badge'
>

export const carouselProductsFilterList = [
  'Meals',
  'Sides',
  'Breakfasts',
  'Treats',
  'Smoothies'
] as const
export type TCarouselProductsFilterList =
  typeof carouselProductsFilterList[number]

export type TCarouselProducts = {
  Meals: TCarouselProduct[]
  Smoothies: TCarouselProduct[]
  Treats: TCarouselProduct[]
  Breakfasts: TCarouselProduct[]
  Sides: TCarouselProduct[]
}

export type TBuildCarouselProducts = (
  products: TTrimmedProduct[]
) => TCarouselProducts
