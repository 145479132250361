import { client } from '@tofu/shared/utils/axios-client'
import { isValidProduct } from '@tofu/shared/types/product'
import { trimProductsData } from '@tofu/shared/utils/trim-products-data'

import { normaliseAndRemoveHiddenProducts } from '@tofu/shared/utils/products-normaliser'

import { TGetProducts, TGetTrimmedProducts } from './products.types'

export const getProduct = async (handle: string) => {
  try {
    const response = await client.get(`/products?handle=${handle}`)
    return response && response.data && response.data.data
  } catch {
    throw new Error('Failed to fetch product')
  }
}

export const fetchProducts = async () =>
  client
    .get(
      `/products?product_type=meal,breakfast,side,smoothie,treat,traybake&shop_visibility=visible`
    )
    .then((response) => response?.data?.data)
    .catch((error) => {
      throw error
    })

export const getProducts: TGetProducts = async () => {
  const response = await fetchProducts()
  const validProducts = response.filter(isValidProduct)
  const normalisedProducts = normaliseAndRemoveHiddenProducts(validProducts)

  return normalisedProducts
}

export const getTrimmedProducts: TGetTrimmedProducts = async () => {
  const response = await fetchProducts()
  const validProducts = response.filter(isValidProduct)
  const trimmedProducts = trimProductsData(validProducts)
  const normalisedProducts = normaliseAndRemoveHiddenProducts(trimmedProducts)

  return normalisedProducts
}
