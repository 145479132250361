import { TProduct } from '@tofu/shared/types/product'
import {
  TrimmedProductSchema,
  TTrimmedProduct
} from '@tofu/shared/types/trimmed-product'

export const trimProductsData = (products: TProduct[]): TTrimmedProduct[] =>
  products.reduce(
    (previousValue: TTrimmedProduct[], currentValue: TProduct) => {
      const result = TrimmedProductSchema.safeParse(currentValue)

      if ('error' in result) {
        console.log(`Failed to parse product. Error: ${result.error}`)

        return previousValue
      }

      return [...previousValue, result.data]
    },
    []
  )
