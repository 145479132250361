export const responsiveCarouselSettings = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024
    },
    slidesToSlide: 4,
    items: 4
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 780
    },
    slidesToSlide: 3,
    items: 3
  },
  phablet: {
    breakpoint: {
      max: 780,
      min: 464
    },
    slidesToSlide: 2,
    items: 2
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0
    },
    slidesToSlide: 1,
    items: 1
  }
}
