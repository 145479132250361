import { Text, TTextProps } from '@tofu/shared/ui/atoms/text'

export const ProductCardTitle = (propsText: TTextProps) => (
  <Text
    textStyle='body-lg'
    fontWeight='medium'
    sx={{
      cursor: 'pointer'
    }}
    as='span'
    mb={1}
    {...propsText}
  />
)
