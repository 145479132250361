import { z } from 'zod'
import {
  Product,
  VariantPortionSize,
  VariantStockStatus
} from '@tofu/shared/types/product'

export const TrimmedProductVariantsSchema = z.object({
  id: z.number(),
  price: z.number(),
  portion_size: VariantPortionSize,
  stock_status: VariantStockStatus,
  original_price: z.number().optional(),
  discounted_percentage: z.number().optional()
})

const TrimmedProductSchemaWithoutVariants = Product.pick({
  cuisine: true,
  dish_type: true,
  display_award_badge: true,
  id: true,
  handle: true,
  short_description: true,
  has_gluten: true,
  has_nuts: true,
  images: true,
  has_soy: true,
  title: true,
  tags: true,
  image_url: true,
  product_type: true,
  is_microwaveable: true,
  is_ovenable: true,
  spice_level: true,
  nutritional_highlights: true,
  is_new: true,
  season: true,
  unit_weight: true,
  resident_chef: true
})

export const TrimmedProductSchema = TrimmedProductSchemaWithoutVariants.extend({
  variants: z.array(TrimmedProductVariantsSchema)
})

export const TrimmedNormalisedProductSchema =
  TrimmedProductSchemaWithoutVariants.extend({
    variants: z.object({
      SINGLE_PORTION: TrimmedProductVariantsSchema,
      DOUBLE_PORTION: TrimmedProductVariantsSchema
    })
  })

export type TTrimmedProduct = z.infer<typeof TrimmedProductSchema>
export type TTrimmedNormalisedProduct = z.infer<
  typeof TrimmedNormalisedProductSchema
>
