/* istanbul ignore file */
import { HStack } from '@tofu/shared/ui/atoms/stack'
import { Box } from '@tofu/shared/ui/atoms/box'

import {
  ProductCard,
  ProductCardTags,
  ProductCardTitle,
  ProductCardImage,
  ProductCardContent,
  ProductCardPriceInfo,
  ProductCardClickableArea
} from '@tofu/shared/ui/molecules/product-card'

import { formatPrice } from '@tofu/shared/utils/format-currency'
import { Link } from '@tofu/shop/ui/atoms/link'

import { TSliceProductCarouselItem } from './slice-product-carousel-item.types'

export const SliceProductCarouselItem: TSliceProductCarouselItem = ({
  handle,
  image_url,
  title,
  onClickAdd,
  variants,
  is_new,
  tags
}) => {
  if (!handle) return null

  /* istanbul ignore next */
  const {
    price: singlePrice
    // discounted_percentage,
    // original_price
  } = variants?.['SINGLE_PORTION'] || {}

  const price = singlePrice
    ? `From ${formatPrice(singlePrice)}`
    : formatPrice(variants?.['DOUBLE_PORTION']?.price || 0)

  return (
    <ProductCard data-testid={`carousel-item-${handle}`} mx={2}>
      <Box position='relative'>
        <ProductCardClickableArea
          as={Link}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignores
          href={`/products/${handle}`}
          // jest dosen't like testing onClicks on this Link, will come back to this
          /* istanbul ignore next */
          onClick={() => onClickAdd(handle)}
          tabIndex={-1}
          aria-label={`view more product information for ${title}`}
          sx={{
            '&:hover': {
              '.arrows': {
                display: 'flex'
              }
            }
          }}
        >
          {image_url && title && (
            <ProductCardImage alt={title} src={image_url} title={title} />
          )}
        </ProductCardClickableArea>
      </Box>
      <Link
        href={`/products/${handle}`}
        aria-label={`view product information for ${title}`}
        // jest dosen't like testing onClicks on this Link, will come back to this
        /* istanbul ignore next */
        onClick={() => onClickAdd(handle)}
      >
        <ProductCardContent>
          <Box w='100%'>
            <ProductCardTags isNew={!!is_new} tags={tags || []} />
            <HStack alignItems='top' justifyContent='space-between'>
              <ProductCardTitle
                aria-label={`view product information for ${title}`}
              >
                {title}
              </ProductCardTitle>
            </HStack>
            {/* <ProductCardDescription>{short_description}</ProductCardDescription> */}
          </Box>

          <Box w='100%'>
            {singlePrice && <ProductCardPriceInfo price={price} />}
          </Box>
        </ProductCardContent>
      </Link>
    </ProductCard>
  )
}
