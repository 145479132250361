import { useStaticContentContext } from '@tofu/shop/providers/static-content-provider'

import { TUseStaticContent } from './use-static-content.types'

export const useStaticContent: TUseStaticContent = () => {
  const { carouselProducts, promoBanner } = useStaticContentContext()

  return {
    carouselProducts,
    promoBanner
  }
}
